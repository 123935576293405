import { DataStateChangeEvent, PagerSettings, SelectableSettings } from '@progress/kendo-angular-grid';
import { Directive, EventEmitter, HostListener, inject, Input, Output } from '@angular/core';
import { Grid, GridChange } from './models';
import { GridService } from './services';

@Directive()
export class GridBaseHandler {
  @Input() grid: Grid;
  @Input() gridId: string;
  @Input() gridEntity: string;
  gridMaxHeightMinus = 250;
  @Input() public set _gridMaxHeightMinus(data: number) {
    if (data) {
      this.gridMaxHeightMinus = data;
      this.gridMaxHeight = window.innerHeight - this.gridMaxHeightMinus;
    }
  }
  @Input() pageableSettings: PagerSettings = { pageSizes: [10, 50, 100] };
  @Input() dataGridFilter: any;

  // Grid change outputs
  @Output() gridChanges: EventEmitter<GridChange> = new EventEmitter<GridChange>();

  public gridMaxHeight = 400;
  public selectableSettings: SelectableSettings = { checkboxOnly: true };

  protected gridService = inject(GridService);

  constructor() {
    this.getScreenSize();
  }

  // Grid state, payload change
  onGridChange(event: DataStateChangeEvent) {
    this.gridChanges.emit({
      gridId: this.gridId,
      entity: this.gridEntity,
      state: event,
      payload: this.grid.payload,
    });
  }

  onSelectionChange($event) {
    if (this.grid.payload.showSelected) {
      this.gridChanges.emit({
        gridId: this.gridId,
        entity: this.gridEntity,
        payload: this.grid.payload,
      });
    }
  }

  // Clear selection
  onClearSelections() {
    this.gridChanges.emit({
      gridId: this.gridId,
      entity: this.gridEntity,
      payload: { showSelected: false, selections: [], selectionsData: [] },
      state: this.grid.state as DataStateChangeEvent,
    });
  }

  onShowSelectedChange(event: boolean) {
    if (event) {
      this.gridChanges.emit({
        gridId: this.gridId,
        entity: this.gridEntity,
        payload: { ...this.grid.payload, showSelected: true },
        state: { ...(this.grid.state as DataStateChangeEvent), take: 100, skip: 0 },
      });
    } else {
      this.gridChanges.emit({
        gridId: this.gridId,
        entity: this.gridEntity,
        payload: { ...this.grid.payload, showSelected: false },
      });
    }
  }

  public expandDetailsBy = (dataItem): string => {
    return dataItem.id;
  };

  expandedDetailKeysChange(ids) {
    this.gridService.setExpandedIds(this.gridId, ids);
  }

  collapse() {
    return this.grid.payload.expandedIds.length;
  }

  expandCollapseAll() {
    if (this.grid.payload.expandedIds.length) {
      this.expandedDetailKeysChange([]);
    } else {
      const allIds = this.grid.data.data.map((x) => x.id);
      this.expandedDetailKeysChange(allIds);
    }
  }

  /* -------------------------------------------------------------------- */

  // obecná funkce pro všechny gridy
  @HostListener('window:resize', ['$event'])
  getScreenSize() {
    this.gridMaxHeight = window.innerHeight - this.gridMaxHeightMinus;
  }
}
