import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { createReducer, on, Action } from '@ngrx/store';
import {
  RefreshGridDataAction,
  GridDataRefreshSuccessAction,
  GridDataRefreshFailedAction,
  GridChangeSelections,
  GridClearSelections,
  GridSetSelections,
  GridSetSelectionsData,
  GridSetState,
  GridSetExpandedIds,
} from '../actions/grid.actions';
import { Grid } from '../models/grid.model';

export interface GridsState extends EntityState<Grid> {}

export const adapter: EntityAdapter<Grid> = createEntityAdapter<Grid>();

export const initialState: GridsState = adapter.getInitialState();

const gridReducer = createReducer(
  initialState,
  on(RefreshGridDataAction, (state, { id, settings, payload }) => {
    const oldState = state.entities[id];
    const newSettings = settings
      ? Object.keys(settings)
          .filter((x) => settings[x] !== undefined)
          .reduce((acc, it) => ({ ...acc, [it]: settings[it] }), {})
      : null;
    return adapter.upsertOne(
      {
        id,
        loading: true,
        data: oldState
          ? oldState.data
          : {
              data: [],
              total: 0,
            },
        state: oldState ? { ...oldState.state, ...newSettings } : newSettings,
        payload: payload ? payload : oldState?.payload,
      },
      state,
    );
  }),
  // tslint:disable-next-line:no-shadowed-variable
  on(GridDataRefreshSuccessAction, (state, { id, gridResponse, total, gridErrors }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: {
          data: gridResponse || [],
          total,
        },
        state: oldState.state,
        errors: gridErrors,
        payload: oldState.payload,
      },
      state,
    );
  }),
  on(GridDataRefreshFailedAction, (state, { id }) => {
    const stateGridBefore = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: {
          data: [],
          total: 0,
        },
        state: stateGridBefore.state,
        errors: {
          errorCode: -1,
          errorDescription: 'Something is wrong, please repeate action.',
          success: false,
        },
        payload: null,
      },
      state,
    );
  }),
  on(GridChangeSelections, (state, { id /*, addedSelections, removedSelections*/ }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState?.data,
        state: oldState?.state,
        errors: oldState?.errors,
        payload: oldState?.payload, //selections: [...oldState.selections.filter(x => removedSelections.findIndex(y => y === x) < 0), ...addedSelections]
      },
      state,
    );
  }),
  on(GridClearSelections, (state, { id }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState?.data,
        state: oldState?.state,
        errors: oldState?.errors,
        payload: {
          showSelected: false,
          selections: [],
        },
      },
      state,
    );
  }),
  on(GridSetSelections, (state, { id, newSelections }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState?.data,
        state: oldState?.state,
        errors: oldState?.errors,
        payload: {
          ...oldState?.payload,
          selections: [...newSelections],
        },
      },
      state,
    );
  }),
  on(GridSetSelectionsData, (state, { id, newSelectionsData }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState?.data,
        state: oldState?.state,
        errors: oldState?.errors,
        payload: {
          ...oldState?.payload,
          selectionsData: newSelectionsData,
        },
      },
      state,
    );
  }),
  on(GridSetState, (state, { id, newState }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState?.data,
        state: newState,
        errors: oldState?.errors,
        payload: oldState?.payload,
      },
      state,
    );
  }),
  on(GridSetExpandedIds, (state, { id, expandedIds }) => {
    const oldState = state.entities[id];
    return adapter.upsertOne(
      {
        id,
        loading: false,
        data: oldState?.data,
        state: oldState?.state,
        errors: oldState?.errors,
        payload: {
          ...oldState?.payload,
          expandedIds: expandedIds,
        },
      },
      state,
    );
  }),
);

export function reducer(state: GridsState | undefined, action: Action) {
  return gridReducer(state, action);
}
