import { provideEffects } from '@ngrx/effects';
import { ActionReducerMap, combineReducers, provideState } from '@ngrx/store';
import { GridsEffects } from './effects/grid.effects';
import { State } from './models';
import { reducer } from './reducers/grid.reducers';

export const reducers: ActionReducerMap<State> = {
  grids: reducer,
};

export const gridProviders = [
  provideState({
    name: 'grids',
    reducer: combineReducers(reducers),
  }),
  provideEffects([GridsEffects]),
];
