import { Injectable } from '@angular/core';
import { ConfigService } from '@cue/admin-core';
import { HttpClient } from '@angular/common/http';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { Observable } from 'rxjs';
import { CONSTANTS } from '@cue/admin-constants';

@Injectable({
  providedIn: 'root',
})
export class GridDataService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {}

  fetchData(urlPostfix: string, event: DataStateChangeEvent, addition, payload): Observable<any> {
    const url = `${this.configService.value.apiURL}`;
    /*if (urlPostfix == "/resourceProperty/grid" || urlPostfix == "/resourcePropertyItem/grid") {
      url = "https://private-7f9a1-touchoneadminapi.apiary-mock.com";
    }*/
    const queryString = toDataSourceRequestString(event);
    let requestUrl = url + CONSTANTS.api.prefix + urlPostfix + '?' + queryString;
    if (addition) {
      requestUrl += '&addition=' + encodeURIComponent(JSON.stringify(addition));
    }
    return this.http.post<any>(requestUrl, { data: payload });
  }
}
